import { Action } from '@ngrx/store';

export enum LogOutActionTypes {
    LogOut = '[Log Out] Clear State',
}


export class LogOut implements Action {
    readonly type = LogOutActionTypes.LogOut;
}

export type LogOutActions = LogOut;